// snackbarSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  message: '',
  vertical: 'top',
  horizontal: 'right',

  severity: 'info', // Possible values: 'success', 'error', 'warning', 'info'
};

const SnackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar(state, action) {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity || 'info';
    },
    closeSnackbar(state) {
      state.open = false;
      state.message = '';
      state.severity = 'info'; // Reset to default severity
    },
  },
});

// Export the actions to be used in components
export const snackbarActions = SnackbarSlice.actions;
export default SnackbarSlice;
