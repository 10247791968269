import { AES, enc } from 'crypto-js';

export function encryptData(data, secretKey) {
  const jsonString = JSON.stringify(data);
  const cipherText = AES.encrypt(jsonString, secretKey).toString();
  return cipherText;
}

export function decryptData(encryptedData, secretKey) {
  const decryptedBytes = AES.decrypt(encryptedData, secretKey);
  const decryptedJsonString = decryptedBytes.toString(enc.Utf8);
  const decryptedObject = JSON.parse(decryptedJsonString);
  return decryptedObject;
}
