import React, { lazy } from 'react';
import AuthRoutes from './authSecure';
import ProtectedRoutes from './ProtectedRoutes';
import BlankLayout from '../Components/layouts/BlankLayout';

const DefaultLayout = lazy(() => import('../Components/layouts/DefaultLayout'));
const Login = lazy(() => import('../Components/Pages/auth/Login'));
const SignUp = lazy(() => import('../Components/Pages/auth/SignUp'));
const VerifyEmail = lazy(() => import('../Components/Pages/auth/VerifyEmail'));
const CheckEmail = lazy(() => import('../Components/Pages/auth/CheckEmail'));

const ForgetPassword = lazy(
  () => import('../Components/Pages/auth/ForgetPassword')
);
const CreatePassword = lazy(
  () => import('../Components/Pages/auth/CreatePassword')
);
const ResetPassword = lazy(
  () => import('../Components/Pages/auth/ResetPassword')
);

const Home = lazy(() => import('../Components/Pages/home/Home'));
const AssignInstructor = lazy(
  () => import('../Components/Pages/assignInstructor/AssignInstructor')
);
const Notifications = lazy(
  () => import('../Components/Pages/notifications/Notifications')
);

const Personnel = lazy(() => import('../Components/Pages/PersonnelPage'));

const FormLayout = lazy(() => import('../Components/layouts/FormLayout'));
const UploadFile = lazy(() => import('../Components/shared/UploadFile'));
const SupportPlan = lazy(
  () => import('../Components/Forms/IndividualSupportPlan')
);
const AddProgram = lazy(() => import('../Components/Pages/AddProgram'));
const BehaviourManagement = lazy(
  () => import('../Components/Forms/BehaviourManagement')
);
const Notes = lazy(() => import('../Components/Pages/Notes'));

const AssignClients = lazy(
  () => import('../Components/Pages/assignInstructor/AssignClients')
);

const routeConfig = [
  {
    path: '/',
    element: <ProtectedRoutes />,
    children: [
      {
        path: '',
        element: <DefaultLayout />,
        children: [
          {
            index: true,
            path: '',
            element: <Home />,
          },
          {
            path: 'assign-instructor',
            element: <AssignInstructor />,
          },
          {
            path: 'notifications',
            element: <Notifications />,
          },
          {
            path: '/personnel',
            element: <Personnel />,
          },
          {
            path: '/assign-clients/:id',
            element: <AssignClients />,
          },
        ],
      },
      {
        path: '/add-client/:id',
        element: <FormLayout type={'add-client'} />,
      },
      {
        path: '/behavior-management-tool/:id',
        element: <FormLayout type={'behavior-management-tool'} />,
      },
      {
        path: '/Individual-support-plan/:id',
        element: <FormLayout type={'Individual-support-plan'} />,
      },
      {
        path: '/upload-consent',
        element: <UploadFile />,
      },
      {
        path: '/upload-assessment',
        element: <UploadFile />,
      },
      {
        path: '/support-plan',
        element: <SupportPlan />,
      },
      {
        path: '/add-program',
        element: <AddProgram />,
      },
      {
        path: '/behaviour-management',
        element: <BehaviourManagement />,
      },
      {
        path: '/results/notes',
        element: <Notes />,
      },
    ],
  },
  {
    path: '/auth',
    element: <AuthRoutes />,
    children: [
      {
        path: '',
        element: <BlankLayout />,
        children: [
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: 'signup',
            element: <SignUp />,
          },
          {
            path: 'forget-password',
            element: <ForgetPassword />,
          },
          {
            path: 'create-password/:token',
            element: <CreatePassword />,
          },
          {
            path: 'reset-password/:token',
            element: <ResetPassword />,
          },
          {
            path: 'verify-email',
            element: <VerifyEmail />,
          },
          {
            path: 'check-email',
            element: <CheckEmail />,
          },
        ],
      },
    ],
  },
];

export default routeConfig;
