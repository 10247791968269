import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  response: [],
};

const BehaviorSlice = createSlice({
  name: 'behavior',
  initialState,
  reducers: {
    GetBehaviorSelector(state, action) {
      const data = action.payload.data;
      // console.log('Data from', data);

      state.response = data;
    },
  },
});
export const behaviorActions = BehaviorSlice.actions;
export default BehaviorSlice;
