import { createSlice } from '@reduxjs/toolkit';
import { setItem, getItem, removeItem } from '../../utils/storage/storage';
import { decryptData, encryptData } from '../../utils/security/security';

const encryptedUserData = getItem('userData');
let userData = null;

if (encryptedUserData) {
  try {
    userData = decryptData(
      encryptedUserData,
      process.env.REACT_APP_SECRET_ENCRYPTION_KEY
    );
  } catch (error) {
    userData = null;
  }
}

const initialState = {
  userinfo: userData,
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    Logout: (state) => {
      removeItem('userData');
      removeItem('token');
      removeItem('isAuth');
    },
    Login(state, action) {
      const data = action.payload;
      console.log('authData', data);
      state.userinfo = data?.userData;
      setItem(
        'userData',
        encryptData(data?.userData, process.env.REACT_APP_SECRET_ENCRYPTION_KEY)
      );
      setItem('token', data?.token);
      setItem('isAuth', true);
    },
  },
});
export const authActions = AuthSlice.actions;
export default AuthSlice;
