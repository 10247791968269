import axios from 'axios';
import { getItem } from '../../utils/storage/storage';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getItem('token');
    config.headers.Authorization = token
      ? `Bearer ${token}`
      : `Bearer ${process.env.REACT_APP_KEY}`;

    return config;
  },
  (error) => {
    // Log request error
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor to log errors during the response phase
apiClient.interceptors.response.use(
  (response) => {
    // Simply return the response if successful
    return response;
  },
  (error) => {
    // Log response error

    // handleError(error.response.status);
    return Promise.reject(error);
  }
);

export default apiClient;
