// store.js
import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './Api/authSlice'; // Adjust the path as necessary
import SnackbarSlice from './snackbarSlice'; // Import your snackbar slice
import ClientSlice from './Api/clientSlice';
import InstructorSlice from './Api/instructorSlice';
import BehaviorSlice from './Api/behaviorForm'; // Import the behavior slice
export const store = configureStore({
  reducer: {
    auth: AuthSlice.reducer,
    snackbar: SnackbarSlice.reducer, // Add the snackbar reducer
    client: ClientSlice.reducer,
    instructor: InstructorSlice.reducer,
    behavior: BehaviorSlice.reducer,
  },
});
