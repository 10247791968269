// SnackbarComponent.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide'; // Import Slide
import { snackbarActions } from '../../../store/snackbarSlice';

const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { open, message, severity, vertical, horizontal } = useSelector(
    (state) => state.snackbar
  );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(snackbarActions.closeSnackbar());
  };

  // Slide transition function
  const SlideTransition = (props) => {
    return <Slide {...props} direction="down" />;
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      TransitionComponent={SlideTransition} // Use SlideTransition directly
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={severity}
        onClose={handleClose}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarComponent;
