import React, { Fragment } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { getItem } from '../utils/storage/storage';
import apiClient from '../services/api/api-client';
import { useDispatch } from 'react-redux';
import { authActions } from '../store/Api/authSlice';

const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = getItem('isAuth') || false;

  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 404) {
        navigate('/404');
      }
      if (error.response.status === 401) {
        navigate('/auth/login');
        dispatch(authActions.Logout());
      }
      return Promise.reject(error);
    }
  );

  return (
    <Fragment>
      {isAuth === true ? <Outlet /> : <Navigate to="/auth/login" />}
    </Fragment>
  );
};

export default ProtectedRoutes;
