import routeConfig from './routeConfig';

import { createBrowserRouter } from 'react-router-dom';
// import { routes } from './routes';

const finalRoutes = routeConfig.map((route) => {
  return {
    ...route,
  };
});

const AppRoutes = createBrowserRouter(finalRoutes);

export default AppRoutes;
