import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from '../src/App/store';
import { Provider } from 'react-redux';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// routers
import { RouterProvider } from 'react-router-dom';
import AppRoutes from './App/router/routes';

// react-query
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// phone
import 'react-international-phone/style.css';

import SnackbarComponent from './App/Components/shared/UI/SnackbarComponent';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
    },
  },
});
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});

root.render(
  <Suspense>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <RouterProvider router={AppRoutes} />
          <ReactQueryDevtools initialIsOpen={false} />
          <SnackbarComponent />
        </StyledEngineProvider>
      </Provider>
    </QueryClientProvider>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
