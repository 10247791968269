import React from 'react';
import './App.css';
import './Assets/style/css/common.css';

import AppRoutes from './App/router/routes';

const App = ({ children }) => {
  return <div className="App">{children}</div>;
};

export default App;
