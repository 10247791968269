import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  response: [],
  metaData: {},
  instructorById: null,
  notAssignedClients: {
    client: [],
    metaData: {},
  },
  assignedClients: {
    client: [],
    metaData: {},
  },
};

const InstructorSlice = createSlice({
  name: 'instructor',
  initialState,
  reducers: {
    GetAll(state, action) {
      const data = action.payload.data;
      state.response = data?.instructors;
      state.metaData = data?.metaData;
    },
    GetById: (state, action) => {
      state.instructorById = action.payload;
    },
    GetNotAssignedClients: (state, action) => {
      console.log('data is come', action.payload);

      state.notAssignedClients.client = action.payload.data.clients;
      state.notAssignedClients.metaData = action.payload.data.metaData;
    },
    GetAssignedClients: (state, action) => {
      state.assignedClients.client = action.payload.data.clients;
      state.assignedClients.metaData = action.payload.data.metaData;
    },
  },
});
export const instructorActions = InstructorSlice.actions;
export default InstructorSlice;
