import React, { Fragment } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { getItem } from '../utils/storage/storage';

const AuthRoutes = () => {
  const isAuth = getItem('isAuth') || false;

  return (
    <Fragment>{isAuth === false ? <Outlet /> : <Navigate to="/" />}</Fragment>
  );
};

export default AuthRoutes;
