import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  response: [],
  metaData: {},
  clientById: {},
};

const ClientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    GetAll(state, action) {
      const data = action.payload.data;
      state.response = data?.clients;
      state.metaData = data?.metaData;
    },
    GetClientById(state, action) {
      const data = action.payload.data;
      console.log('data is come for client by id', data);

      state.clientById = data;
    },
    Add(state, action) {
      const data = action.payload;
      state.response = [data, ...(state.response || [])];
    },
    AddClientInfo(state, action) {
      const data = action.payload;
      state.response = [data, ...(state.response || [])];
    },
  },
});
export const clientActions = ClientSlice.actions;
export default ClientSlice;
